import * as React from "react"
// import { Link } from "gatsby"

const HeroServices = props => (
  <div className="relative min-h-screen w-full -mb-14">
    <div className=" h-4/5 w-full absolute hero-services">
      <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 right-0 hidden md:block"></div>
    </div>

    <div className="absolute top-20 md:top-52 md:max-w-2xl md:mr-10 md:ml-20 ml-5 mt-5 md:mx-5 ">
      <h1 className="text-white text-4xl md:text-6xl md:w-10/12 font-normal tracking-wide md:leading-80">
        {props.title}
      </h1>
      <div>
        <p className="text-white w-9/12 text-lg tracking-wide">
          {props.subTitle}
        </p>
      </div>

      <div className="flex mt-6">
        <a href="#contact" className="text-white border white p-2 mr-4">
          GET A FREE QUOTE
        </a>
      </div>
    </div>
  </div>
)

export default HeroServices
