import * as React from "react"
// import { Link } from "gatsby"

const Contact = () => (
  <div id="contact" className="service-bg pt-6 md:pt-24 pb-24">
    <div className="grid grid-cols-1 md:grid-cols-2 justify-center m-auto max-w-6xl mt-10 mb-20 px-4 md:px-0">
      <div className=" col-span-1 max-w-sm">
        <h2 className=" text-5xl font-normal tracking-wide leading-80">
          We'd love to hear from you
        </h2>

        <p className="text-base tracking-wide">
          Get in touch for any queries, our professional team are always on hand
          to answer any that you have and help get your project started.
        </p>
      </div>

      <div className="">
        <form
          netlify
          netlify-honeypot="bot-field"
          name="contact"
          method="POST"
          data-netlify="true"
          onSubmit="submit"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <label htmlFor="first-name">First Name</label>
              <input
                type="text"
                class=" w-full p-2 border border-black"
                name="first name"
                id="first name"
              />
            </div>

            <div>
              <label htmlFor="last-name">Last Name</label>
              <input
                type="text"
                class=" w-full p-2 border border-black"
                name="last name"
                id="last name"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6">
            <div>
              <label htmlFor="email-address">Email Address</label>
              <input
                type="text"
                class=" w-full p-2 border border-black"
                name="email address"
                id="email address"
              />
            </div>

            <div>
              <label htmlFor="phone-number">Phone Number</label>
              <input
                type="text"
                class=" w-full p-2 border border-black"
                name="last name"
                id="last name"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-5 mt-6">
            <div>
              <label htmlFor="email-address">Message</label>
              <input
                type="text"
                class=" w-full p-10 border border-black"
                name="message"
                id="message"
              />
            </div>

            <div className="mt-6">
              <button
                type="submit"
                className="text-white primary-green p-4 px-6 border-0"
              >
                SEND MESSAGE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
)

export default Contact
