import * as React from "react"
import Contact from "../components/contact/contact"
import HeroServices from "../components/hero/hero-service"
import TextRight from "../components/image-left-text-right"
import TextLeftImage from "../components/image-right-text-left"
import Layout from "../components/layout"
import Seo from "../components/seo"
import about from "../images/2405-loft1.jpeg"
import newBuild from "../images/2405-newBuild.jpeg"
import extensions from "../images/2405-extensions.jpeg"
import commercial from "../images/commercial.png"

const Services = () => (
  <Layout>
    <Seo title="Services" />

    <HeroServices
      title="Take a look at our services"
      subTitle="We offer a wide range of services, no job is too big or too small"
    />

    <TextRight
      title="Extensions"
      textOne="House extensions are an incredible way to improve your home, and we are here to help you get it done!  "
      textTwo="
      They are a great way of improving your home, increasing space for your family to spend more time together in beautiful open plan living or an extra extra bedroom for your growing family."
      image={extensions}
    />

    <TextLeftImage
      title="New Builds"
      textOne="We have a team to take on all types of New Build projects, from single house builds to multi house contracts. We will guide you through the process, ensuring that projects are completed in a timely manner to the highest quality. Get in touch to find out more "
      image={newBuild}
    />

    <TextRight
      title="Loft Conversions"
      textOne="Similar to extensions, this is a great way to improve your house and create more space, without having to move from your settled home! 
      We are able to take advantage of a huge amount of unused space and create something special."
      image={about}
    />

    <TextLeftImage
      title="Commercial Work"
      textOne="We have worked with a wide number of commerical clients to give their venues a fresh lease of life and also new businesses that want to create the perfect space. "
      textTwo="
      We will guide you through the full process, taking all of your ideas and making them a reality"
      image={commercial}
    />

    <Contact />
  </Layout>
)

export default Services
