import * as React from "react"
// import { Link } from "gatsby"

const TextLeftImage = (props) => (
  <div className="grid grid-cols-1 md:grid-cols-2 justify-center m-auto md:max-w-6xl px-7 pb-20">
      <div className="md:mr-32">
          <h2 className="text-4xl md:text-5xl font-normal tracking-wide leading-80">{props.title}</h2>

          <p className="text-base tracking-wide">
            {props.textOne}
          </p>

          <p className=" text-base tracking-wide">
          {props.textTwo}
          </p>

          <button className=" text-green-500 border border-green-500 p-2 mr-4 mt-8">
            {props.button}
          </button>
      </div>
      
      <div className=""> 
        <img src={props.image} alt="about-us" />
      </div>

      

  </div>
)


export default TextLeftImage
