import * as React from "react"
// import { Link } from "gatsby"

const TextRight = props => (
  <div className="grid grid-cols-1 md:grid-cols-2 justify-center m-auto md:max-w-6xl px-7 pb-20">
    <div className="block">
      <img src={props.image} alt="about-us" />
    </div>

    <div className=" md:ml-32 md:mx-0">
      <h2 className="text-4xl md:text-5xl font-normal tracking-wide leading-80">
        {props.title}
      </h2>

      <p className="text-base tracking-wide">{props.textOne}</p>

      <p className=" text-base tracking-wide">{props.textTwo}</p>

      <a
        href={props.link}
        className=" text-green-500 border border-green-500 p-2 mr-4 mt-8"
      >
        {props.button}
      </a>
    </div>
  </div>
)

export default TextRight
